<template>
  <div class="prompts-config-wrap">
    <a-table :columns="tableColumns" :data-source="tableData" :pagination="false" :rowKey="record => record.id">
      <template slot="action" slot-scope="row">
        <a-button type="link" :data-id="row.key" @click="configPrompts(row)">
          配置
        </a-button>
      </template>
    </a-table>
    <a-modal :visible="visible.prompt.show" title="配置提示词" width="900px" @ok="handlePromptSubmit"
      @cancel="visible.prompt.show = false">
      <a-form :form="promptform" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" ref="promptform">
        <a-form-item label="汇总内容">
          <!-- <div class="sum-content" style="">{{ sum.content }}</div> -->
          <a-textarea v-model="sum.content" :rows="5" />
        </a-form-item>
        <a-form-item label="提示词">
          <a-textarea v-decorator="['prompt', { rules: [{ required: true, message: '请输入指令' }] }]"
            :auto-size="{ minRows: 5, maxRows: 10 }" />
        </a-form-item>
        <a-form-item label="AI汇总结果">
          <a-spin :spinning="sum.spinning" tip="正在汇总...">
            <div class="sum-result">{{ sum.result }}</div>
          </a-spin>
          <a-button type="primary" @click="getSumResult">
            获取AI结果
          </a-button>
        </a-form-item>
      </a-form>
    </a-modal>

  </div>
</template>

<script>
const columns = [
  {
    title: '板块名称',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' },
    width: 200
  },
  {
    title: '提示词',
    dataIndex: 'prompt',
    scopedSlots: { customRender: 'prompt' },
    ellipsis: true,
  },
  {
    scopedSlots: { customRender: 'action' },
    key: 'action',
    width: 220,
  },
];
import { getClassifyList, updatePromptClassify } from "@/api/service.js";
import { getSummaryDetailsList, getSumResult } from "@/api/config.js";
export default {
  name: 'PromptsConfig',
  data() {
    return {
      tableColumns: columns,
      tableData: [],
      visible: {
        prompt: {
          show: false,
          id: '',
          prompt: ''
        }
      },
      sum: {
        content: '',
        result: '',
        spinning: false
      },
      promptform: this.$form.createForm(this),

    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 获取汇总结果
    getSumResult() {
      // 汇总内容 有值
      if (!this.sum.content.trim()) {
        this.$message.warning({ content: '无汇总内容；', duration: 2 });
        return
      }
      let prompt = this.promptform.getFieldValue('prompt');
      // 提示词 有值
      if (!prompt.trim()) {
        this.$message.warning({ content: '请输入提示词', duration: 2 });
        return
      }
      this.sum.spinning = true;
      getSumResult({
        prompt,
        content: this.sum.content
      }).then(res => {
        this.sum.result = res.data.data.sumContent;
        this.sum.spinning = false;
      }).catch(err => {
        this.sum.spinning = false;
      })
    },
    handlePromptSubmit() {
      this.promptform.validateFields((err, values) => {
        if (!err) {
          values.id = this.visible.prompt.id;
          updatePromptClassify(values).then(res => {
            this.$message.success({ content: '提示词配置成功！' });
            this.visible.prompt.show = false;
            this.getList();
          }).catch(err => {
            this.$message.error({ content: '提示词配置失败！' + err });
          })
        }
      });
    },
    configPrompts(row) {
      this.sum.content = '';
      // 根据板块获取最新的汇总明细
      getSummaryDetailsList({
        classifyId: `${row.parentId},${row.id}`
      }).then(res => {
        let list = res.data.data;
        let content = ``;
        list.forEach(item => {
          content += `发布者：${item.userName}\n推文内容：${item.content}\n`;
          content += `========================================\n`;
        });
        content = content.replace(/https:\/\/t\.co\/\S+/g, '');
        this.sum.content = content;
      }).catch(err=>{
        this.$message.error({ content: '暂无汇总内容' });

      })
      this.visible.prompt.id = row.id;
      this.visible.prompt.prompt = row.prompt;
      this.sum.result = '';
      this.visible.prompt.show = true;

      this.$nextTick(() => {
        if (this.promptform) {
          this.promptform.setFieldsValue({ prompt: row.prompt });
        }
      });
      // 根据sumid获取汇总结果
    },
    getList() {
      getClassifyList().then(res => {
        this.tableData = res.data.data;
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.ant-input {
  font-weight: 400;
}

.sum-content,
.sum-result {
  white-space: pre-line;
  height: 150px;
  overflow: auto;
  border: 1px solid #D9D9D9;
  border-radius: 6px;
  line-height: 2em;
}
</style>